import {useEffect, useState, useMemo} from 'react'
import {IDate, IMatch, IMatchResult, IMatchSummarys} from "./interface";
import {VStore} from "./store";
import {AuthPopup, Match, User} from "./components";
import './_app.scss';
import {FirebaseUtils} from "./utils/firebaseUtils.ts";


function App() {
    const {matches, setMatches, user, matchResult, setMatchResult} = VStore();

    const [matchSummary, setMatchSummary] = useState<IMatchSummarys>({});

    function retrieveMatchResult(data: IMatchResult) {
        setMatchResult(data);
    }

    async function retrieveMatchData() {
        const data = await FirebaseUtils.getMatchData();
        const matches: IMatch[] = data.rounds.flatMap((round: { matches: IMatch[], name: string }) => round.matches);

        setMatches(matches);
    }

    useEffect(() => {

        retrieveMatchData();

        FirebaseUtils.refData(retrieveMatchResult);

    }, [])

    const dateData = useMemo(() => {
        if (matches.length > 0) {
            const m: IMatch[] = JSON.parse(JSON.stringify(matches));

            Object.entries(matchSummary).forEach(([matchId, summary]) => {
                const index = m.findIndex(match => match.num.toString() === matchId);
                if (index !== -1) {
                    matches[index].matchSummary = summary;
                }
            })

            const dates = matches.map(match => match.date).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

            const _dateData: IDate = {};

            dates.forEach(date => {
                _dateData[date] = matches.filter(match => match.date === date);
            })


            return _dateData;
        }
        return {};
    }, [matches, matchSummary])

    useEffect(() => {
        const userId = user?.uid;
        if (Object.values(matchResult).length > 0) {
            const _matchSummary: IMatchSummarys = {};
            Object.entries(matchResult).forEach(([matchId, votes]) => {
                const temp: { win: number, draw: number, lose: number } = {
                    win: 0,
                    draw: 0,
                    lose: 0
                };
                Object.values(votes).forEach(vote => {
                    switch (vote) {
                        case 'win':
                            temp.win++;
                            break;
                        case 'draw':
                            temp.draw++;
                            break;
                        case 'lose':
                            temp.lose++;
                            break;
                    }
                })
                const userVote = userId ? votes[userId] : '';
                _matchSummary[matchId] = {...temp, userVote};
            })
            setMatchSummary(_matchSummary);
        }
    }, [matchResult, user])


    return (
        <div className='v-wrapper'>
            <div className='v-header'>
                <img src="./assets/images/icons/icon.svg" alt="UEFA Euro 2024"/>
                <User/>
            </div>
            {
                dateData && Object.keys(dateData).map(date => {
                    return (
                        <div key={date} className='v-date'>
                            <h2>{date}</h2>
                            <div className='v-matches'>
                                {
                                    dateData[date].sort((a, b) => parseInt(a.time.split(":")[0]) - parseInt(b.time.split(":")[0]))
                                        .map(match => {
                                            return <Match key={match.num} match={match}/>
                                        })
                                }
                            </div>
                        </div>
                    )
                })
            }
            <AuthPopup/>
        </div>
    )
}

export default App
