import {MouseEvent} from "react";
import './_auth-popup.scss';
import {VStore} from "../../store";
import {FirebaseUtils} from "../../utils/firebaseUtils.ts";

export default function AuthPopup() {
    const {isShowAuthPopup, setIsShowAuthPopup} = VStore();

    function handleGoogleAuth(e: MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        FirebaseUtils.signInWithGoogle();
    }

    function handleCloseAuthPopup() {
        setIsShowAuthPopup(false);
    }

    return (<div className={"auth-popup" + (isShowAuthPopup ? ' show' : '')}>
        <div className='auth-popup__backdrop'
                onClick={handleCloseAuthPopup}
        ></div>
        <div className='auth-popup__content'>
            <h3>
                Đăng nhập
            </h3>
            <h4>với</h4>
            <div className='auth-provider'>
                <button onClick={handleGoogleAuth}>
                    <img src="./assets/images/icons/google-icon.svg" alt="Google"/>
                    <span>Google</span>
                </button>
            </div>
            <button className='auth-popup__close-btn'
                    onClick={handleCloseAuthPopup}
            >
                &#215;
            </button>
        </div>
    </div>);
}
