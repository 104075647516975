import './_user.scss';
import {useEffect} from "react";
import {FirebaseUtils} from "../../utils/firebaseUtils.ts";
import {VStore} from "../../store";

export default function User() {

    const {user, setUser, setIsShowAuthPopup, matches} = VStore();

    function onAuthStateChanged(user: any) {
        setUser(user)
        if (user) {
            setIsShowAuthPopup(false)
        }
    }

    useEffect(() => {
        FirebaseUtils.checkAuthState(onAuthStateChanged)
    }, [])

    return (<div className={"user" + (matches.length ? ' show' : '')}>
        <div>
            <div className='user__avatar'
                 onClick={() => {
                     if (!user) {
                         setIsShowAuthPopup(true)
                     }
                 }}
            >
                <img src={
                    user?.photoURL || './assets/images/icons/avatar.svg'
                } alt="User"/>
            </div>
            {
                user && <div className='user__auth'>
                    <button onClick={FirebaseUtils.signOut}>Sign Out</button>
                </div>
            }
        </div>
    </div>);
}
