import {useState} from "react";
import {IMatch} from "../../interface";
import './_match.scss';
import {VStore} from "../../store";
import {FirebaseUtils} from "../../utils/firebaseUtils.ts";

export type MatchProps = {
    match: IMatch,
}

export default function Match({match}: MatchProps) {
    const {user, setIsShowAuthPopup,} = VStore();

    const [isShowChannel, setIsShowChannel] = useState(false);

    function handleVote(val: string) {
        if (!user) {
            setIsShowAuthPopup(true);
            return
        } else {
            FirebaseUtils.handleVote(user.uid, match.num.toString(), val);
        }
    }

    const matchSummary = match.matchSummary;

    function getPercent(type: 'win' | 'draw' | 'lose') {
        return matchSummary ? Math.round(matchSummary[type] / (matchSummary.win + matchSummary.draw + matchSummary.lose) * 10000) / 100 + '%' : '';
    }

    const votePercent = {
        win: getPercent('win'),
        draw: getPercent('draw'),
        lose: getPercent('lose'),
    }


    const getState = () => {
        const matchDateTimeString = `${match.date}T${match.time}:00`;
        const matchDateTime = new Date(matchDateTimeString);
        const now = new Date();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        const timeDifference = matchDateTime - now;
        const timeDifferenceInHours =  timeDifference / (1000 * 60 * 60);

        if (timeDifferenceInHours > -2 && timeDifferenceInHours <= 0) {
            return "live";
        } else if (timeDifferenceInHours >0 && timeDifferenceInHours <= 2) {
            return "upcoming";
        } else if (timeDifferenceInHours > 2) {
            return "future";
        } else {
            return "past";
        }
    }

    console.log(getState())

    const score = match.score ? <div className='v-match__score'>
        <span>
            {match.score.split('-')[0]}
        </span>
        <span>-</span>
        <span>
            {match.score.split('-')[1]}
        </span>
    </div> : '';

    return (<div className={"v-match " + (getState())}>
        <div>
            <span><b>{match.group.replace('Group', 'Bảng')}</b></span>
        </div>
        <div>
            <span>{match.time}</span>
        </div>
        <div className='v-match__team'>
            <div>
                <img src={`./assets/images/flags/${match.team1.code.toLowerCase()}.svg`} alt={match.team1.name}/>
                <span>{match.team1.name}</span>
            </div>
            {
                score
            }
            <div>
                <img src={`./assets/images/flags/${match.team2.code.toLowerCase()}.svg`} alt={match.team2.name}/>
                <span>{match.team2.name}</span>
            </div>

        </div>
        <div className={'v-match__result'}>
            <div className='v-match__result__ratio'>
                {
                    matchSummary?.win || ''
                }
            </div>
            <div className='v-match__result__ratio'>
                {
                    matchSummary?.draw || ''
                }
            </div>
            <div className='v-match__result__ratio'>
                {
                    matchSummary?.lose || ''
                }
            </div>
            <div className={'win-btn vote-btn' + (matchSummary?.userVote === 'win' ? ' active' : '')}
                 onClick={() => handleVote('win')}
            >
                Thắng
            </div>
            <div className={'draw-btn vote-btn' + (matchSummary?.userVote === 'draw' ? ' active' : '')}
                 onClick={() => handleVote('draw')}>
                Hòa
            </div>
            <div className={'lose-btn vote-btn' + (matchSummary?.userVote === 'lose' ? ' active' : '')}
                 onClick={() => handleVote('lose')}>
                Thua
            </div>
            <div className='v-match__result__ratio'>
                {votePercent.win}
            </div>
            <div className='v-match__result__ratio'>
                {votePercent.draw}
            </div>
            <div className='v-match__result__ratio'>
                {votePercent.lose}
            </div>
        </div>
        <div className='v-match__channel'>
            <button onClick={() => setIsShowChannel(true)}>Kênh truyền hình</button>
            <span className='v-match__channel__live' onClick={() => setIsShowChannel(true)}>Trực tiếp</span>
        </div>

        <div className={'v-match__channel-modal' + (isShowChannel ? ' show' : '')}
            onClick={() => setIsShowChannel(false)}
        >
            <div
                onClick={(e) => e.stopPropagation()}
            >
                <button className='close-btn' onClick={() => setIsShowChannel(false)}>&#215;</button>
                <table>
                    <tbody>
                    <tr>
                        <td colSpan={2}><b>Trực tiếp</b></td>
                    </tr>
                    <tr>
                        <td>
                            {match.channel}
                        </td>
                        <td>
                            <button className={'watch-btn'}>
                                <a href='https://tv360.vn/' rel='noreferrer' target='_blank'>
                                    <img src="./assets/images/icons/play.svg" alt="play"/>
                                    <span>Xem</span>
                                </a>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>);
}
