import {create} from 'zustand'
import {IMatch, IMatchResult} from "../interface";


type VStoreType = {
    matches: IMatch[],
    setMatches: (matches: IMatch[]) => void

    user: any
    setUser: (user: any) => void

    isShowAuthPopup: boolean
    setIsShowAuthPopup: (isShowAuthPopup: boolean) => void

    matchResult: IMatchResult
    setMatchResult: (matchResult: IMatchResult) => void

}

export const VStore = create<VStoreType>((set) => ({
    matches: [],
    setMatches: (matches) => set({matches}),

    user: null,
    setUser: (user) => set({user}),

    isShowAuthPopup: false,
    setIsShowAuthPopup: (isShowAuthPopup) => set({isShowAuthPopup}),

    matchResult: {},
    setMatchResult: (matchResult) => set({matchResult}),

}))


