import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
import {get, getDatabase, onValue, ref as databaseRef, update} from "firebase/database";
import {getAuth, GoogleAuthProvider, signInWithPopup, RecaptchaVerifier} from "firebase/auth";
import {IMatchResult} from "../interface";
import {firebaseConfig} from "../config/firebase.ts";


class FirebaseSingleton {
    private readonly _app;
    private readonly _database;
    private readonly _voteRef;
    private readonly _auth;
    private readonly _googleProvider;
    private readonly _analytics;

    constructor() {
        this._app = initializeApp(firebaseConfig);
        this._database = getDatabase(this._app);
        this._voteRef = databaseRef(this._database, '/votes');
        this._auth = getAuth(this._app);
        this._googleProvider = new GoogleAuthProvider();
        this._analytics = getAnalytics(this._app);

        this.signInWithGoogle = this.signInWithGoogle.bind(this);
        this.recaptchaVerifier = this.recaptchaVerifier.bind(this);
    }

    public refData(callBack: (val: IMatchResult) => void): void {
        onValue(this._voteRef, (snapshot) => {
            if (snapshot.exists()) {
                callBack(snapshot.val());
            }
        });
    }

    public async getMatchData(): Promise<any> {
        try {
            const _ref = databaseRef(this._database, 'matches');
            return await get(_ref).then((snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val();
                } else {
                    return null;
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    public async handleVote(userId: string, matchId: string, vote: string): Promise<void> {
        try {
            const _ref = databaseRef(this._database, `votes/${matchId}`);
            await update(_ref, {[userId]: vote});
        } catch (error) {
            console.error(error)
        }
    }

    public async signInWithGoogle(): Promise<void> {
        try {
            const result = await signInWithPopup(this._auth, this._googleProvider);
            console.log(result)
        } catch (error) {
            console.error(error)
        }
    }

    public signOut = async (): Promise<void> => {
        try {
            await this._auth.signOut();
        } catch (error) {
            console.error(error)
        }
    }

    public checkAuthState = (callBack: (user: any) => void): void => {
        this._auth.onAuthStateChanged(callBack);
    }

    protected getAnalytics(): void {
        logEvent(this._analytics, 'test_event');
    }

    public recaptchaVerifier(): RecaptchaVerifier {
        return new RecaptchaVerifier(this._auth,'recaptcha-container', {});
    }

}

export const FirebaseUtils = new FirebaseSingleton();
