const firebaseConfig = {
    apiKey: "AIzaSyC6LIahRQEhcOQeMu9VYdISL9j6s9kX3FE",
    authDomain: "euro-2024-592c8.firebaseapp.com",
    databaseURL: "https://euro-2024-592c8-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "euro-2024-592c8",
    storageBucket: "euro-2024-592c8.appspot.com",
    messagingSenderId: "964353709107",
    appId: "1:964353709107:web:1103e791198dd196d1ff46",
    measurementId: "G-WP4ERWPS1J"
};

export { firebaseConfig}
